/* TYPOGRAPHY */
@import url('https://dudbm6bcnmy8e.cloudfront.net/cwru/fonts/Arizona-Fonts/arizona-sans.css');
@import url('https://dudbm6bcnmy8e.cloudfront.net/cwru/fonts/Arizona-Fonts/arizona-sans-alt.css');
@import url('https://dudbm6bcnmy8e.cloudfront.net/cwru/fonts/Arizona-Fonts/arizona-text.css');

/* DECLARE VARS */
:root {
  --font-plain: 'Arizona', sans-serif;
  --font-text: 'Arizona Text', serif;
  --color-drk-blue: #09143a;
  --color-cwru-blue: #003071;
  --color-link-blue: #006da3;
  --color-green: #61a530;
  --color-blue-green: #377e72;
  --color-yellow: #e69e40;
  --color-orange: #d63d1f;
  --color-dark-gray: #333;
  --color-light-gray: #c6c6c6;
  --color-cwru-light-gray: #d3d3d3;
  --color-lighter-gray: #eee;
  --color-off-white: #f5f5f5;
  --color-white: #ffffff;
  --color-off-black: #1f2325;
  --color-black: #000000;
  --font-weight-thin: 200;
  --font-weight-normal: 400;
  --font-weight-semibold: 600;
  --font-weight-bold: 700;
  --font-weight-black: 900;
  --max-container: 1244px;
  --bp-mobile: 55rem;
  --ratio: 1.4;
  --s-5: calc(var(--s0) / var(--ratio) / var(--ratio) / var(--ratio) / var(--ratio) / var(--ratio));
  --s-4: calc(var(--s0) / var(--ratio) / var(--ratio) / var(--ratio) / var(--ratio));
  --s-3: calc(var(--s0) / var(--ratio) / var(--ratio) / var(--ratio));
  --s-2: calc(var(--s0) / var(--ratio) / var(--ratio));
  --s-1: calc(var(--s0) / var(--ratio));
  --s0: 1rem;
  --s1: calc(var(--s0) * var(--ratio));
  --s2: calc(var(--s0) * var(--ratio) * var(--ratio));
  --s3: calc(var(--s0) * var(--ratio) * var(--ratio) * var(--ratio));
  --s4: calc(var(--s0) * var(--ratio) * var(--ratio) * var(--ratio) * var(--ratio));
  --s5: calc(var(--s0) * var(--ratio) * var(--ratio) * var(--ratio) * var(--ratio) * var(--ratio));
  --measure: 65ch;
  --line-height: var(--s1);
  --line-height-small: calc(0.8 * var(--ratio));
  --border-thin: 1px;
  --border-thick: var(--s-2);
  line-height: var(--line-height);
  font-size: calc(0.333vw + 1em);
  font-family: var(--font-plain);
  background-color: var(--color-off-white);
  color: #000;
}
html {
  overflow-x: hidden;
}
*,
:after,
:before {
  box-sizing: border-box;
  background-color: inherit;
  font-family: inherit;
  color: inherit;
  overflow-wrap: break-word;
  margin: 0;
  padding: 0;
  border: 0 solid;
}
[hidden] {
  display: none;
}
[hidden] + * {
  margin-top: 0 !important;
}
footer,
header,
main,
nav {
  display: block;
}
@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}
:focus {
  outline: var(--border-thin) solid var(--color-light-gray);
  outline-offset: var(--border-thin);
}
[tabindex='-1']:focus {
  outline: none;
}

/* GENERAL STYLES */
* {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
img {
  width: 100%;
}
table {
  width: 100%;
  border-collapse: collapse;
}
table code {
  white-space: nowrap;
}
td,
th {
  border-width: var(--border-thin);
  padding: var(--s-1);
  text-align: left;
}
td:first-child {
  font-weight: 700;
}
h1,
h2,
h3,
h4 {
  line-height: var(--line-height-small);
  font-family: var(--font-special);
  font-weight: var(--font-weight-semibold);
  hyphens: auto;
}
.h1,
h1 {
  font-size: var(--s3);
  font-weight: var(--font-weight-bold);
}
.h2,
h2 {
  font-size: var(--s2);
  color: var(--color-cwru-blue);
}
.h3,
h3 {
  font-size: var(--s1);
}
.h4,
h4 {
  font-size: var(--s0);
}
hr {
  padding: var(--border-thin) 0 0;
  border-left-width: 0;
  border-bottom-width: var(--border-thin);
  border-right-width: 0;
  border-top-width: var(--border-thin);
}
button {
  outline: var(--border-thin) solid transparent;
  outline-offset: calc(var(--border-thin) * -1);
}
a,
a:link,
a:visited {
  text-decoration: none;
  color: var(--color-link-blue);
  font-weight: var(--font-weight-bold);
  cursor: pointer;
  background-color: transparent;
}
ul {
  padding: 0 0 0 var(--s2);
}
ul li + li {
  margin-top: var(--s-1);
}

/* LAYOUT STYLES */
p + p {
  margin-top: var(--s-1);
}

main[slot='main'] {
  border-left: var(--border-thin) solid var(--color-off-white);
}
/* This is temporary! All of this will change when we have proper layout components. */
/* .main-container {
  display: flex;
  flex-wrap: wrap;
  background: #fff;
  max-width: 1244px;
  margin: 0 auto;
  padding: var(--s-1);
}
.main-sidebar,
.main-content {
  padding-top: var(--s-1);
  padding-bottom: var(--s-1);
}
.main-sidebar {
  flex-basis: 15rem;
  flex-grow: 1;
  position: relative;
  border-right: var(--border-thin) solid var(--color-lighter-gray);
  display: none;
  padding-right: var(--s-1);
}
.main-content {
  flex-basis: 0;
  flex-grow: 999;
  min-width: 66%;
  display: flex;
  flex-direction: column;
  padding-left: var(--s-1);
}

.main-content > * + * {
  margin-top: var(--s0);
}

@media (min-width: 55rem) {
  .main-sidebar {
    display: block;
  }
} */

.sec-nav-link {
  font-size: 14px;
}
.main-content {
  padding: var(--s1);
}
.main-content > * + * {
  margin-top: var(--s0);
}

/* UTILITY CLASSES */
.hidden {
  display: none !important;
}
.background-white {
  background-color: #fff;
}
.hidden-mobile {
  display: none;
}
@media (min-width: 55rem) {
  .hidden-mobile {
    display: block;
  }
}
